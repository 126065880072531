






































import {Vue, Component} from 'vue-property-decorator';
import {ApplicationModule} from '@/store/modules/application';
import {UserModule} from '@/store/modules/user';

@Component({})
export default class Register extends Vue {
    private email = '';

    private password = '';

    private hidePassword = true;

    private error = '';

    private get loading() {
        return ApplicationModule.loading;
    }

    private register(event: Event): void {
        event.preventDefault();
        UserModule.register({username: this.email, password: this.password});
        this.$router.push('/account/login');
    }
}
